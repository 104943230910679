:root {
    --black: #000000;
    --red: #8D2526;
    --accent:#EFF6F4;
    --white: white;
    --grey: #f8f8f8;
    --border: 1px solid grey;
    --rad: 10px;
    --gap: 20px;
    --shadow: 0px 0px 10px rgba(0, 0, 0, 0.103);
    --text: #202020e1;
    --trans: all 0.33s ease-in-out;
    --width: 1200px;
    
  }
  
  *,
  *::after,
  *::before {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    // border: var(--border);
    font-family: "Poppins", sans-serif;
  }
  
  @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
  
  // font-family: 'Poppins', sans-serif;
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
  }
  
  p,
  li,
  input,
  option,
  select,
  textarea {
    letter-spacing: 1px;
    word-spacing: 2px;
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
    color: var(--text);
  }
  
  .box {
    background: var(--grey);
    border-radius: var(--rad);
  }
  
  body {
    width: 100%;
    overflow-x: hidden;
    background: white;
  }
 
  
  .btn {
    padding: 10px 30px;
    font-size: 16px;
    border: none;
    border-radius: calc(var(--rad) / 3);
    background: var(--accent);
    color: var(--white);
    transition: var(--trans);
  
    &:hover {
      transform: translateY(-4px) scale(1.05);
    }
  }
  
  .parent {
    width: 100svw;
  
    .cont {
      max-width: var(--width);
      margin: auto;
      height: 100%;
      padding: 0px 10px;
    }
  } 
   
  .bg-img-cover {
    background-size: cover !important ;
    background-repeat: no-repeat !important;
    background-position: center center !important;
  }
  .bg-img-contain {
    background-size: cover !important ;
    background-repeat: no-repeat !important;
    background-position: center center !important;
  }

  a{
    text-decoration: none;
  }
 
  
  
  
  

  
  

  
 
  
 
  
  
  
 
  