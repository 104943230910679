.footer-parent {
    height: auto;
    background: rgba(0, 0, 0, 0.911);

    .footer-cont {
        padding: 40px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;


        .footer-content-box {
            width: 100%;
            display: flex;
            padding-left: 30px;

            .footer-pages {
                width: 25%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;

                p {
                    font-size: 18px;
                    font-weight: 500;
                    color: var(--white);
                }

                a {
                    text-decoration: none;
                    color: var(--text);
                    font-size: 16px;

                    &:hover {
                        color: var(--accent);
                    }
                }
            }

            .footer-services {
                width: 25%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;

                p {
                    font-size: 18px;
                    font-weight: 500;
                    color: var(--white);
                }

                a {
                    text-decoration: none;
                    color: var(--text);
                    font-size: 16px;

                    &:hover {
                        color: var(--accent);
                    }
                }
            }

            .footer-legal {
                width: 25%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                p {
                    font-size: 18px;
                    font-weight: 500;
                    color: var(--white);
                }

                a {
                    text-decoration: none;
                    color: var(--text);
                    font-size: 16px;

                    &:hover {
                        color: var(--accent);
                    }
                }
            }

            .footer-contact {
                width: 25%;
                display: flex;
                flex-direction: column;
                gap: 30px;

                .footer-phone,
                .footer-mail,
                .footer-add {
                    width: 100%;
                    display: flex;
                    align-items: center !important;
                    gap: 20px;
                    align-items: flex-start;

                    .footer-icon-glass {
                        background: rgba(163, 163, 163, 0.26);
                        align-items: center;
                        border-radius: 50%;
                        color: var(--accent);
                        display: flex;
                        font-size: 20px;
                        height: 35px;
                        justify-content: center;
                        padding: 8px;
                    }
                }

                p {
                    font-size: 16px;
                    font-weight: 300;
                    color: var(--text);
                    text-align: left !important;
                }

                a {
                    text-decoration: none;
                    color: var(--text);
                    font-size: 16px;

                    &:hover {
                        color: var(--accent);
                    }
                }
            }
        }
    }
}