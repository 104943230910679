.header-parent {
    width: 100%;
    height: 100px;
    box-shadow: var(--shadow);
    padding: 10px 0px;
    // position: fixed;
    top: 0px;

    .header-cont {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .header-logo {
            background: url('../assets/logo.jpg');
            height: 90px;
            width: 120px;
        }

        .header-no {
            background: var(--red);
            padding: 10px 20px;
            display: flex;
            gap: var(--gap);
            align-items: center;
            justify-content: center;
            border-radius: 5px;

            .icon {
                font-size: 24px;
                display: flex;
                color: white;
            }

            .no {
                color: white;
            }
        }
    }
}