.v-m-parent {
    margin: 50px 0px;
   

    .v-m-cont {
        display: flex;
        gap: 20px;
        align-items: center;

        .box {
            width: 350px;
            padding: 20px;
            height: auto;
            display: flex;
            flex-direction: column;
            gap: 15px;
            background: var(--accent);

            .v-icon{
                font-size: 40px;
                font-weight: 700;
                color: var(--red);
            }
        }
    }
}


@media only screen and (max-width:1000px){
    .v-m-cont {
        flex-direction: column !important;
    }
}