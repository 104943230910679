.parent-contact {
  margin: 50px 0px;

  .cont-contact {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 20px;
    padding: 10px;
    align-items: center;

    .contact-left {
      width: 40%;
      height: 400px;
      background: url("../assets/boy.webp");
      border-radius: 5px;
    }

    .contact-right {
      width: 60%;
      height: 400px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      justify-content: center;

      .contacts-list {
        width: 450px;
        padding: 0px;
        display: flex;
        flex-direction: column;
        gap: var(--gap);

        .contact-item {
          width: 100%;
          background: var(--accent);
          border-radius: calc(var(--rad) / 2);
          padding: 15px;
          display: flex;
          gap: calc(var(--gap) / 2);
          align-items: flex-start;

          .icons {
            width: 60px;
            aspect-ratio: 1 !important;
            background: var(--red);
            border-radius: calc(var(--rad) / 2);
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--white);
            font-size: 22px;
          }

          .item-details {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }

          a {
            color: black;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .cont-contact {
    flex-direction: column !important;
  }

  .contact-left {
    width: 100% !important;
    height: 500px !important;
  }

  .contact-right {
    width: 100% !important;
  }
}

@media only screen and (max-width: 460px) {
  .contacts-list {
    width: 100% !important;
    .contact-item {
      width: 400px !important;
    }
  }
}
